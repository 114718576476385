import {Question} from './Question';

const questions: Question[] = [
    {
        question: "You have created a process definition which uses several webhook based Inbound Connectors. The process has been deployed using Web Modeler to several Camunda SaaS clusters.\n" +
            "\n" +
            "Where can you find the URL of deployed webhooks in Camunda SaaS?",
        options: [
            "In Web Modeler, under the Settings tab in the details of a project for a process definition",
            "In the Console, under the Webhook tab in the settings of a cluster for deployed process definitions",
            "In Web Modeler, under the Webhook tab in the details panel of the Connector in a process definition",
            "In the Console, under the Activity tab, in the details of an organization for deployed process definitions",
        ],
        answers: [2],
    },
    {
        question: "You have successfully logged into the Web Modeler and are at the Home page.\n" +
            "\n" +
            "You want to create a new project in Camunda using the Web Modeler. Which of the following steps should you take?",
        options: [
            "Open the Web Modeler settings and select 'Create New Project'.",
            "Click on 'New Project' and enter the name when prompted.",
            "Right-click on the canvas and select 'New Project'.",
            "Navigate to 'File' menu and select 'New Project'.",
        ],
        answers: [1],
    },
    {
        question: "Your team is working on a BPMN model for an order process. Send Tasks are being used to represent outgoing customer communication.\n" +
            "\n" +
            "Currently, your process solution has two different Job Workers to process the customer communication, one being a legacy system that is being phased out. You have been asked to design the BPMN process model in such a way that it stays concise and stable during and after the phase-out.\n" +
            "\n" +
            "Which of the following solutions keeps the BPMN model concise and stable throughout and after the phase-out of the legacy system?",
        options: [
            "Use an Exclusive Gateway and two Send Tasks, one for each Job Worker, and set the conditions on the sequence flows to control which Send Task is run.",
            "Use one Send Task for both Job Workers by configuring the retry condition to first run the legacy Job Worker and switch to the other on failure.",
            "Use one Send Task for both Job Workers by using a dynamic value for the task type of the Send Task to control which Job Worker is used.",
            "Use a Parallel Gateway and two Send Tasks, one for each Job Worker, and keep the legacy Job Worker after the phase-out while removing its business code.",
        ],
        answers: [2],
    },
    {
        question: "Due to a partial outage of your network infrastructure, job workers were unable to reach several services. This led to a high number of incidents.\n" +
            "\n" +
            "What is the most efficient way to view all error messages and how many incidents exist per message?",
        options: [
            "Use the Zeebe API to request a list of all error messages and incidents.",
            "View the incidents by error message on the Operate process view.",
            "Check the logs of the Job Workers for the error messages.",
            "View the incidents by error message on the Operate dashboard.",
        ],
        answers: [3],
    },
];

export default questions;